h1, h2, h3 {
font-family: "termina", sans-serif;
font-weight: 800;
font-style: normal;
}

body {
  margin: 0;
  font-family: semplicitapro, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.lead {
  font-weight: 600
}

.app-background {
  background-image:url('./assets/images/gradient-pinkblue.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.bold {
  font-weight: bold;
}

.padding {
  padding: 20px;
}